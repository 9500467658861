import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
   
    { title: 'Inicio', url: '/home', icon: 'home' },

    { title: 'Mapa', url: '/mapa', icon: 'location' },

    { title: 'Listado', url: '/obras', icon: 'list' },

    { title: 'Fuentes', url: '/enlaces', icon: 'library' },
 
    { title: 'Acerca del Proyecto', url: '/acerca', icon: 'eye' }
    
  ];
 
  constructor() {}
}
